
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";
export default defineComponent({
  props: {
    id: Number,
  },
  setup() {
    const exportpdf = ref(true);
    const route = useRoute();
    const store = useStore();
    async function Exportpdf() {
      await store.dispatch(
        `WalletModule/${Actions.FETCH_WALLET_TRANSACTION_BY_INVOICE}`,
        {
          transactionIdinvoice: route.params.bill_number,
        }
      );
      setTimeout(function () {
        window.print();
      }, 1000);
    }
    const walletTransactionDetail = computed(() => {
      return store.state.WalletModule.walletTransactionInvoice;
    });
    onMounted(() => {
      Exportpdf();
    });
    return {
      Exportpdf,
      walletTransactionDetail,
      exportpdf,
    };
  },
});
